<template lang="">
  <div>
    <!--头部-->
    <header id="header">
      <div id="top1">
        <a href="javascript:void(0)">佛山市</a> 欢迎来到<a href="https://www.cyxn.com/">创业小鸟</a>，创业就找创业小鸟！
        <!-- <div class="toptext">
          <a href="javascript:void(0)" class="login">请登录</a>
          <a href="javascript:void(0)"><span class="fc register">立即注册</span> </a>|
          <a href="javascript:void(0);" class="login">我的订单</a> |
        </div> -->
        <ul class="topul">
          <li>
            <router-link  to="/operate">经营范围</router-link>
          </li>
          <li>
            <router-link  to="/sbfl">商标分类</router-link>
          </li>
          <li>
            <a href="javascript:void(0)">新人福利</a> |
            <div class="tc_new1">
              <dl>
                <dt><img src="@/assets/images/41896d4b5748ec51841c2afd43dc170e.jpg"></dt>
                <dd>官方公众号<br>扫码关注，领取你的福利</dd>
              </dl>
            </div>
          </li>
          <li>
            <a href="javascript:void(0)">客户服务</a>
            <div class="tc_new2">
              <dl>
                <dd><router-link to="/pay">支付方式</router-link></dd>
              </dl>
            </div>
          </li>
          <li>
            <a href="javascript:void(0)"> 网站导航</a>
            <div class="tc_new3">
              <dl v-for="(item,index) in serviceList" :key="index">
                <dt><router-link :to="{path:'/service',query:{category_id:item.id}}">{{item.name}}</router-link></dt>
                <dd v-for="(item2,index2) in item.content">
                  <router-link
                    :to="{path:'/detail',query:{category_id:item.id,product_id:item2.pro_id}}">{{item2.pro_title}}</router-link>
                </dd>
              </dl>
              <div class="clear"></div>
              <ol class="clearfix">
              </ol>
            </div>
          </li>
          <li>
            <el-dropdown>
              <span class="el-dropdown-link">
                <a href="javascript:void(0)">旗下网站</a><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu class="custom-dropdown" slot="dropdown" v-if="linkList.length>0">
                <el-dropdown-item v-for="(item,index) in linkList" :key="item.id" :command="item.name">
                  <a :href="item.link">{{item.name}}</a></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>

      <div id="top2">
        <div class="topsum">
          <div id="logo"><a href="https://www.cyxn.com/"><img src="@/assets/images/22c8da7f0b57587a0ce60c3fce0707d7.png"
                alt="创业小鸟"></a></div>
          <form class="top_search" onsubmit="return false;">
            <input name="content" type="text" class="input" id="tcSearchValue1" placeholder="请输入公司名称+行业，如创业小鸟科技"
              onfocus="this.placeholder=&#39;&#39;" onblur="this.placeholder=&#39;请输入公司名称+行业，如创业小鸟科技&#39;">
            <input name="type" type="hidden" value="财税服务">
            <input type="button" class="btn" value="免费查询能否注册" @click="openDialog">
          </form>
        </div>
        <div class="navas">
          <div class="topsum">
            <div id="subanv">
              <span @mouseenter="onMouseEnter">全部服务分类</span>
              <ul @mouseleave="onMouseLeave" v-show="shouldShowUl">
                <li v-for="(item,index) in nameList.slice(0,5)" :key="index">
                  <a href="javascript:void(0)">{{item.name}}&gt;</a>
                  <div class="subanv_text">
                    <dl v-for="(item2,index2) in item.content" :key="index2" class="clearfix">
                      <dt>
                        <router-link :to="{path:'/more',query:{id:item2[0].second_category_id}}">{{item2[0].title}} &gt;
                        </router-link>
                      </dt>
                      <dd>
                        <router-link v-for="(item3,index3) in item2.slice(0,9)"
                          :to="{path:'/detail',query:{category_id:item3.main_category_id,product_id:item3.pro_id}}">{{item3.pro_name}}</router-link>
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
            <ul id="nav">
              <li @click="changeIndex2" :class="{active:$route.path=='/'}"><a href="/">首页</a></li>
              <!-- <li v-for="(item,index) in newTopList" :key="index" :class="{active:$route.query.category_id==item.id}"
                @click="changeIndex(item)">
                <router-link
                  :to="item.title === '集群地址' ? { path: '/address',query:{category_id:item.id,id:1} } : { path: '/service', query: { category_id: item.id } }">{{item.title}}</router-link>
              </li> -->
              <li @click="changeIndex2" :class="{active2:$route.path.includes('/service')}" @mouseover="onFenlei=true" @mouseleave="onFenlei=false">
                <router-link :to="{ path: '/service', query: { category_id: newTopList[0].id } }">全部分类
                </router-link>
                <ul v-show="onFenlei">
                  <li v-for="(item,index) in newTopList" :key="index"
                    :class="{active:$route.query.category_id==item.id}" @click="changeIndex(item)">
                    <router-link
                      :to="item.title === '集群地址' ? { path: '/address',query:{category_id:item.id,id:1} } : { path: '/service', query: { category_id: item.id } }">{{item.title}}</router-link>
                  </li>
                </ul>
              </li>
              <!-- <li @click="changeIndex2" :class="{active:$route.path==='/operate'}"><router-link
                  to="/operate">经营范围</router-link>
              </li> -->
              <li @click="changeIndex2" :class="{active:$route.path==='/policy'}"><router-link
                  to="/policy">政策法规</router-link>
              </li>
              <li @click="changeIndex2" :class="{active:$route.path==='/knowledge'}"><router-link
                  to="/knowledge">知识库</router-link>
              </li>
              <li @click="changeIndex2" :class="{active:$route.path==='/video'}"><router-link
                  to="/video">企业视频</router-link>
              </li>
              <li @click="changeIndex2" :class="{active:$route.path==='/party'}"><router-link
                  to="/party">非公党建</router-link>
              </li>
              <li @click="changeIndex2" :class="{active:$route.path==='/about'}"><router-link
                  to="/about">关于我们</router-link>
              </li>
              <li @click="changeIndex2" :class="{active:$route.path==='/contact'}"><router-link
                to="/contact">联系我们</router-link>
            </li>
            </ul>
          </div>
        </div>
      </div>
      <GlobalDialog ref="globalDialog" />
    </header>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        onFenlei:false,
        activeItem: null,
        TopList: [],
        serviceList: [],
        service2List: [],
        nameList: [],
        isHovering: true,
      }
    },
    created() {
      if (this.$route.path !== '/') {
        this.Hovering = false
      }
    },
    mounted() {
      this.getTop();
      this.getService();
      this.getService2();
      this.$store.dispatch('getLink')
    },
    watch: {
      '$route'(to, from) {
        if (to.path !== '/') {
          this.isHovering = false;
        }

      },
    },
    computed: {
      linkList() {
        return this.$store.state.linkList;
      },
      newTopList() {
        let newList = [...this.TopList];
        newList.splice(2, 0, { title: '集群地址', id: 6 });
        return newList;
      },
      shouldShowUl() {
        // 如果鼠标悬停在 ul 上或者当前路由是 "/"，则显示 ul
        return this.isHovering || this.$route.path === '/';
      },
    },
    methods: {
      openDialog() {
        this.$refs.globalDialog.dialogVisible = true;
      },
      // 鼠标进入 ul 时更新 isHovering
      onMouseEnter() {
        if (this.$route.path !== '/') {
          this.isHovering = true;
        }
      },
      // 鼠标离开 ul 时更新 isHovering
      onMouseLeave() {
        if (this.$route.path !== '/') {
          this.isHovering = false;
        }
      },
      async getTop() {
        let res = await this.$request.post('servicemaincategory/getAll', {
          fil: 1
        })
        if (res.data.code === 0) {
          this.TopList = res.data.data
        }
      },

      async getService() {
        let res = await this.$request.post('servicemaincategory/getCategoryProduct', {
          category_id: this.$route.query.category_id
        })
        if (res.data.code === 0) {
          this.serviceList = res.data.data
          this.serviceList = this.changeApply(this.serviceList)
        }
      },

      async getService2() {
        let res = await this.$request.post('servicesecondcategory/getAll', {
          fil: 1
        })
        if (res.data.code === 0) {
          this.service2List = res.data.data
          this.service2List = this.changeApply(this.service2List)
          this.service2List = this.service2List.sort((a, b) => a.sort - b.sort);
        }
        this.nameList = this.groupByThree(this.service2List);

      },
      groupByThree(arr) {
        const result = [];
        for (let i = 0; i < arr.length; i += 3) {
          const group = arr.slice(i, i + 3);
          const groupedData = {
            name: group.map(item => item.name).join('，'),
            content: group.map(item => item.content),
            // 如果需要其他字段也可以在这里组合
          };
          result.push(groupedData);
        }
        return result;
      },

      changeIndex(item) {
        if (item.title === '集群地址') {
          this.activeItem = 'cluster-address';
        } else {
          this.activeItem = item.id;
        }
      },
      changeIndex2() {
        this.activeIndex = null
      },

      changeApply(arr) {
        // 使用reduce函数来分组
        const groupedByDomain = arr.reduce((groups, item) => {
          // 如果该domain（分类ID）的组不存在，则创建一个新组
          if (!groups[item.id]) {
            groups[item.id] = {
              bottom_banner: item.bottom_banner,
              common_questions: item.common_questions,
              name: item.title, // 分类名称
              id: item.id,//分类id
              sort: item.sort,
              description: item.description,
              content: [] // 初始化内容数组
            };
          }
          // 将当前项添加到对应domain（分类ID）的组中
          groups[item.id].content.push(item);
          return groups;
        }, {});

        // 将分组后的对象数组化
        const result = Object.values(groupedByDomain);
        return result
      },
    },
  }
</script>
<style lang="css">
  .isShow {
    display: block !important;
  }

  .homeBlue {
    color: #195c99;
  }

  .el-dropdown-menu {
    max-height: 250px;
    /* 设置你想要的最大高度 */
    overflow-y: auto;
    /* 当内容超出最大高度时显示垂直滚动条 */
  }

  .el-dropdown-menu__item:hover {
    background-color: #f0f0f0;
    /* 设置你想要的背景颜色 */
  }
</style>