import Vue from 'vue'
import App from './App.vue'
import router from '@/libs/router.js'
import store from './store'
import request from '@/libs/request.js'
import GlobalDialog from './components/appDialog.vue';
import GlobalDialog2 from './components/appDialog2.vue';
import GlobalDialog3 from './components/appDialog3.vue';
import Teamdialog from '@/components/teamDialog'
import '@/assets/css/page.css'
import '@/assets/css/css.css'
import '@/assets/css/header-need.css'
import '@/assets/css/tc.css'
import '@/assets/css/list.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import DOMPurify from 'dompurify'

Vue.prototype.$sanitize=function (html) {
  return DOMPurify.sanitize(html);
}

Vue.use(ElementUI)

//使用vue-meta
import VueMeta from 'vue-meta';

Vue.use(VueMeta);


import VueLazyload from 'vue-lazyload';
//图片懒加载
Vue.use(VueLazyload);


// 使用swiper插件
Vue.use(VueAwesomeSwiper)

//html转义方法
Vue.prototype.$deTag=function (Value) {
  let text = Value.replace(/<[^>]*>/g,'')
  text = text.replace(/&[^;]+;/g, '');
  return text
}

Vue.config.productionTip = false
//全局请求
Vue.prototype.$request = request

Vue.component('GlobalDialog', GlobalDialog);
Vue.component('GlobalDialog2', GlobalDialog2);
Vue.component('GlobalDialog3', GlobalDialog3);
Vue.component('TeamDialog', Teamdialog);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
