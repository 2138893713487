<template lang="">
    <div>
        <!--banner-->
        <div id="slideBox2" class="slideBox2">
            <div class="hd">
                <ul class="dot">
                    <li @click="goToSlide(index)" v-for="(item, index) in currentBannerList" :key="index"
                        :class="{ on: index === currentIndex }">
                        {{ index + 1 }}
                    </li>
                </ul>
            </div>
            <div class="bd">
                <swiper :options="homeSwiper" ref="mySwiper" @slideChange="onSlideChange">
                    <swiper-slide v-for="(item,index) in currentBannerList" :key="index">
                        <router-link to="/"><img :src="item.picture" alt=""></router-link>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <!--热销·精选服务-->
        <div class="index_sum1">
            <div class="w_1180 clearfix">
                <h2 class="bg1 rexiao"><strong ><span class="rexiao_span">热销</span>· 精选服务</strong></h2>
                <router-link :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}"
                    v-for="(item,index) in hotService" :key="index">
                    <div class="sum1_block">
                        <dl class="dl1">
                            <dt></dt>
                            <h4>{{item.title}}</h4>
                            <div style="width: 30px; height: 2px; background-color: #FF9631; margin: 0 auto;"></div>
                            <dd>{{item.description}}</dd>
                            <dd><span>¥{{item.price}}</span>起</dd>
                        </dl>
                        <p>立即购买</p>
                    </div>
                </router-link>
            </div>
        </div>

        <!--财税服务-->
        <div class="w_1180 clearfix height_480" v-if="taxList.length>0">
            <h2 class="bg1"><strong>财税服务</strong>节省企业经营成本 为您量身定制节税方案<span>
                    <router-link
                        :to="{path:'/service',query:{category_id:taxList[0].main_category_id}}">More</router-link></span>
            </h2>
            <div class="yinying">
                <router-link :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}"
                    v-for="(item,index) in taxList[0]?.content.slice(0,4)" :key="index">
                    <div class="sum1_block">
                        <h4>{{item.title}}</h4>
                        <h4>{{item.description}}</h4>
                        <dl class="dl2">
                            <dd>
                                <span>¥{{item.price}} <span style="color: #a7a2a2; font-size: 14px;">起</span> </span>
                            </dd>
                            <p>立即购买</p>
                        </dl>
                    </div>
                </router-link>
            </div>
        </div>
        <!--孵化服务-->
        <div class="f_bg" style="padding-top:25px;" v-if="incubateList.length>0">
            <div class="w_1180 clearfix height_480">
                <h2 class="bg1"><strong>孵化服务</strong>从创意到落地，加速企业成长<span><router-link
                            :to="{path:'/service',query:{category_id:incubateList[0].main_category_id}}">More</router-link></span>
                </h2>
                <div class="yinying">
                    <router-link :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}"
                        v-for="(item,index) in incubateList[0]?.content.slice(0,4)" :key="index">
                        <div class="sum1_block">
                            <h4>{{item.title}}</h4>
                            <h4>{{item.description}}</h4>
                            <dl class="dl2">
                                <dd>
                                    <span>¥{{item.price}} <span style="color: #a7a2a2; font-size: 14px;">起</span>
                                    </span>
                                </dd>
                                <p>立即购买</p>
                            </dl>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <!--知识产权-->
        <div class="w_1180 clearfix height_480" v-if="intelList.length>0">
            <h2 class="bg1"><strong>知识产权</strong>专业团队护航，保护您的合法权益<span><router-link
                        :to="{path:'/service',query:{category_id:intelList[0].main_category_id}}">More</router-link></span>
            </h2>
            <div class="yinying">
                <router-link v-for="(item,index) in intelList[0]?.content.slice(0,4)" :key="index"
                    :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}">
                    <div class="sum1_block">
                        <h4>{{item.title}}</h4>
                        <h4>{{item.description}}</h4>
                        <dl class="dl2">
                            <dd>
                                <span>¥{{item.price}} <span style="color: #a7a2a2; font-size: 14px;">起</span> </span>
                            </dd>
                            <p>立即购买</p>
                        </dl>
                    </div>
                </router-link>
            </div>
        </div>
        <!--资质认定-->
        <div class="f_bg" style="padding-top:25px;" v-if="qualificateList.length>0">
            <div class="w_1180 clearfix height_480">
                <h2 class="bg1"><strong>资质认定</strong>专业资质办理，省时省心<span><router-link
                            :to="{path:'/service',query:{category_id:qualificateList[0].main_category_id}}">More</router-link></span>
                </h2>
                <div class="yinying">
                    <router-link v-for="(item,index) in qualificateList[0]?.content.slice(0,4)" :key="index"
                        :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}">
                        <div class="sum1_block">
                            <h4>{{item.title}}</h4>
                            <h4>{{item.description}}</h4>
                            <dl class="dl2">
                                <dd>
                                    <span>¥{{item.price}} <span style="color: #a7a2a2; font-size: 14px;">起</span>
                                    </span>
                                </dd>
                                <p>立即购买</p>
                            </dl>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <!--电商服务-->
        <div class="w_1180 clearfix height_480" v-if="shopList.length>0">
            <h2 class="bg1"><strong>电商服务</strong>一站式电商服务，助力品牌推广<span><router-link
                        :to="{path:'/service',query:{category_id:shopList[0].main_category_id}}">More</router-link></span>
            </h2>
            <div class="yinying" >
                <router-link v-for="(item,index) in shopList[0]?.content.slice(0,4)" :key="index"
                    :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}">
                    <div class="sum1_block">
                        <h4>{{item.title}}</h4>
                        <h4>{{item.description}}</h4>
                        <dl class="dl2">
                            <dd>
                                <span>¥{{item.price}} <span style="color: #a7a2a2; font-size: 14px;">起</span> </span>
                            </dd>
                            <p>立即购买</p>
                        </dl>
                    </div>
                </router-link>
            </div>
        </div>

        <!--顾问团队-->
        <div class="" style="padding-top:65px; border-top: 1px solid #EBEBEB;">
            <div class="w_1180 clearfix">
                <h2 class="bg1"><strong>顾问团队</strong>企业方案、企业税务专家团<span><router-link to="/team">More</router-link></span>
                </h2>
                <div class="new_team" v-if="teamList.length>0">
                    <ul style="padding:30px 0 60px;">
                        <li v-for="(item,index) in teamList" :key="index">
                            <a  @click="handleClick(item.title,item.picture,item.company_position,item.description)" href="javascript:void(0);">
                                <img v-lazy="item.picture">
                                <span>{{item.title}}</span>
                                <font>{{item.company_position}}</font>
                                <div class="box">
                                    <p class="p1" v-html="item.description">
                                    </p>
                                    <p class="p2">擅长：<br>{{item.skills}}</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

        <!--新闻资讯-->
        <div class="f_bg" style="padding-top:25px;" v-if="newsList.length>0">
            <div class="w_1180 clearfix news_module">
                <h2 class="bg1"><strong>新闻资讯</strong>关注最新动态，创业资讯早知道<span><router-link
                            :to="{path:'/news',query:{category_id:newsList[0].category_id}}">More</router-link></span>
                </h2>

                <div class="maxWidth">
                    <ul v-for="(item,index) in newsList?.slice(0,4)" :key="index">
                        <li>
                            <router-link :to="{path:'/newsdetail',query:{id:item.id}}">
                                <div class="left">
                                    <div>
                                        <p class="date">{{item.day}}<span></span></p>
                                        <p>{{item.years}}-{{item.month}}</p>
                                    </div>
                                </div>
                                <div class="right">
                                    <p class="title">{{item.title}}</p>
                                    <p class="txt">
                                        {{item.description}}
                                    </p>
                                    <p class="type">{{item.category_title}}</p>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!--合作客户-->
        <div id="link">
            <div class="w_1180 clearfix">
                <h2 class="bg2"><strong>合作客户</strong><span><router-link
                            :to="{path:'/partner'}">More</router-link></span>见证者，感谢有您</h2>
                <ul class="link_ul" v-if="partnerList.length>0">
                    <li v-for="(item,index) in partnerList.slice(0,10)" :key="index">
                        <a :href="item.link" title="清华大学" target="_blank">
                            <img v-lazy="item.logo">
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!--底部-->
        <div class="index-services">
            <div class="w_1180">
                <h2 class="bg1"><strong>我们的服务承诺</strong></h2>
                <ul class="ul1">
                    <li class="ico1"><span>服务保障</span>
                        <p>专属客服对接<br>一对一提供服务</p>
                    </li>
                    <li class="ico2"><span>时间保障</span>及时提交<br>快速办理<p></p>
                    </li>
                    <li class="ico3"><span>诚信保障</span>
                        <p>服务全程跟踪<br>严格保护客户资料</p>
                    </li>
                    <li class="ico4"><span>先行赔付</span>
                        <p>出错先赔付<br>代账更放心</p>
                    </li>
                    <li class="ico5"><span>先行赔付</span>
                        <p>出错先赔付<br>代账更放心</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="cpn">
            <div class="aProject">
                <div class="close" onclick="login.hideLoginDialog()"><img src="@/assets/images/menu_close.png" alt="">
                </div>
                <div class="detailMain">

                    <div class="left">
                        <p><img src="@/assets/images/logo.png"></p>
                        <ul>
                            <li><img src="@/assets/images/ico.png">智能记账，低至360元/年</li>
                            <li><img src="@/assets/images/ico.png">创业记账工具，简单可靠</li>
                            <li><img src="@/assets/images/ico.png">10000+创业者的选择</li>
                            <li><img src="@/assets/images/ico.png">打造财务机器人第一品牌</li>
                        </ul>
                    </div>
                    <div class="right">
                        <p class="p1">微信扫码立即登录</p>
                        <p class="p2"><img src="https://www.cyxn.com/" id="wechatLoginQrCode"></p>
                        <p class="p3"><input name="" type="checkbox" value="" checked=""> 我已阅读并同意
                            <br><a href="https://www.cyxn.com/license/agreement.html"
                                target="_blank">《创业小鸟网络服务使用协议》</a>和<a
                                href="https://www.cyxn.com/license/privacyPolicy.html" target="_blank">《创业鸟用户隐私条款》</a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
        <TeamDialog ref="teamdialog" :teamTitle="teamTitle" :company_position="company_position" :image="teamimage" :description="description" />
    </div>
</template>

<script>
    
    export default {
        data() {
            return {
                teamTitle:'',
                teamimage:'',
                description:'',
                company_position:'',
                currentIndex: 0,
                BannerList: [],
                BannerList1: [],
                BannerList2: [],
                hotService: [],
                ServiceList: [],
                taxList: [],
                incubateList: [],
                intelList: [],
                shopList: [],
                teamList: [],
                newsList: [],
                partnerList: [],
                qualificateList: [],
                is4K: false,
                homeSwiper: {
                    autoplay: {
                        delay: 3000, // 自动播放间隔时间
                        disableOnInteraction: false // 用户交互后继续自动播放
                    },
                    pagination: {
                        el: "",
                        clickable: true
                    }
                }
            }
        },
        components:{
        },
        created() {
            this.getBanner()
            this.getService()
            this.getTeam()
            this.getNews()
            this.getPartner()
            document.title = '创业小鸟 - 全球注册，全国通办'
            window.addEventListener('resize', this.checkScreenResolution);
        },
        destroyed() {
            window.removeEventListener('resize', this.checkScreenResolution);
        },
        computed: {
            currentBannerList() {
                return window.innerWidth >= 2560 ? this.BannerList2 : this.BannerList1;
            }
        },
        methods: {
            handleClick(title,image,company_position,description){
                this.teamTitle=title;
                this.teamimage=image;
                this.company_position=company_position;
                this.description=description
                this.$refs.teamdialog.dialogVisible=true
            },
            goToSlide(index) {
                this.$refs.mySwiper.swiper.slideTo(index, 300, true); // 300是过渡时间，true表示回调函数
            },
            onSlideChange() {
                this.currentIndex = this.$refs.mySwiper.swiper.activeIndex;
            },
            async getBanner() {
                let res = await this.$request.post('banner/getAll')
                if (res.data.code === 0) {
                    this.BannerList = res.data.data
                    this.BannerList1 = this.BannerList.filter(item => !item.title.includes('2K'))
                    this.BannerList2 = this.BannerList.filter(item => item.title.includes('2K'))
                }
            },
            async getService() {
                let res = await this.$request.post('servicecategory/getPic')
                if (res.data.code === 0) {
                    this.ServiceList = res.data.data
                    this.hotService = this.ServiceList.filter(item => item.is_hot === '是')
                    let newServiceList = this.changeApply(this.ServiceList)
                    this.taxList = newServiceList.filter(item => item.main_category_id === 1)
                    if (this.taxList[0].content.length > 0 && this.taxList !== undefined) {
                        this.taxList[0].content = this.taxList[0].content.filter(item => item.is_top === 1)
                    }
                    this.incubateList = newServiceList.filter(item => item.main_category_id === 5)
                    if (this.incubateList[0].content.length > 0 && this.incubateList !== undefined) {
                        this.incubateList[0].content = this.incubateList[0].content.filter(item => item.is_top === 1)
                    }
                    this.shopList = newServiceList.filter(item => item.main_category_id === 4)
                    if (this.shopList[0].content.length > 0 && this.shopList !== undefined) {
                        this.shopList[0].content = this.shopList[0].content.filter(item => item.is_top === 1)
                    }
                    this.intelList = newServiceList.filter(item => item.main_category_id === 2)
                    if (this.intelList[0].content.length > 0 && this.intelList !== undefined) {
                        this.intelList[0].content = this.intelList[0].content.filter(item => item.is_top === 1)
                    }
                    this.qualificateList = newServiceList.filter(item => item.main_category_id === 3)
                    if (this.qualificateList[0].content.length > 0 && this.qualificateList !== undefined) {
                        this.qualificateList[0].content = this.qualificateList[0].content.filter(item => item.is_top === 1)
                    }
                }
            },
            changeApply(arr) {
                // 使用reduce函数来分组
                const groupedByDomain = arr.reduce((groups, item) => {
                    // 如果该domain（分类ID）的组不存在，则创建一个新组
                    if (!groups[item.main_category_id]) {
                        groups[item.main_category_id] = {
                            main_category_title: item.main_category_title, // 分类名称
                            main_category_id: item.main_category_id, // 分类id
                            content: [] // 初始化内容数组
                        };
                    }
                    // 将当前项添加到对应domain（分类ID）的组中
                    groups[item.main_category_id]?.content.push(item);
                    return groups;
                }, {});

                // 将分组后的对象数组化
                const result = Object.values(groupedByDomain);
                return result
            },
            async getTeam() {
                let res = await this.$request.post('team/getAll')
                if (res.data.code === 0) {
                    this.teamList = res.data.data.slice(0, 4)
                    console.log(this.teamList,'顾问');
                }
            },
            async getNews() {
                let res = await this.$request.post('news/getAll', {
                    fil: 1
                })
                if (res.data.code === 0) {
                    this.newsList = res.data.data.slice(0, 4)
                    this.newsList.forEach(item => {
                        item.created_at = item.created_at.split(' ')
                        item.created_at = item.created_at[0].split('-')
                        item.years = item.created_at[0]
                        item.month = item.created_at[1]
                        item.day = item.created_at[2]
                    })

                }
            },
            async getPartner() {
                let res = await this.$request.post('partner/getAll')
                if (res.data.code === 0) {
                    this.partnerList = res.data.data
                }
            },
            checkScreenResolution() {
                this.is4K = window.matchMedia("(min-width: 2560px)").matches;
            }
        },
    }
</script>
<style lang="css" scoped>
    .image_client_pic {
        width: 500px;
        /* 设置容器宽度 */
        margin: 0 auto;
        /* 居中显示 */
    }

    .client_box {
        width: 100%;
    }

    .swiper-container {
        width: 100%;
    }

    .swiper-slide {
        width: 100px;
        /* 每个slide的宽度 */
        height: auto;
        /* 高度自适应 */
    }

    .swiper-slide img {
        width: 100%;
        /* 图片宽度100% */
        height: auto;
        /* 高度自适应 */
    }

    .client_box /deep/ .swiper-wrapper {

        transition-timing-function: linear !important;

    }
</style>