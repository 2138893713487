<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="780px"
    :lockScroll="false"
    >
     <div class="detailMain">
            <div class="left">
                <p><img src="/public/static/web/images/logo.png"></p>
                <ul>
                    <li><img src="/public/static/web/images/ico.png">智能记账，低至360元/年</li>
                    <li><img src="/public/static/web/images/ico.png">创业记账工具，简单可靠</li>
                    <li><img src="/public/static/web/images/ico.png">10000+创业者的选择</li>
                    <li><img src="/public/static/web/images/ico.png">打造财务机器人第一品牌</li>
                </ul>
            </div>
            <div class="right">
                <p class="p1" style="font-size: 18px; font-weight: 700;">扫码立即联系</p>
                <p class="p2">
                  <img src="@/assets/images/service_code.png" alt="">
                  </p>
                <p class="p3"><input name="" type="checkbox" value="" checked=""> 我已阅读并同意
                    <br><a href="/agreement" target="_blank">《创业小鸟网络服务使用协议》</a>和
                    <a href="/privacy" target="_blank">《创业鸟用户隐私条款》</a></p>
            </div>
      
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      consultationForm: {
        contact_phone: '',
        contact_name: '',
        agreeProtocol:false
      },
      rules: {
        contact_phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async submitConsultation() {
      if (this.consultationForm.agreeProtocol) {
        this.$refs.consultationForm.validate(async valid => {
        if (valid) {
          let res = await this.$request.post('clusterreserve/add',this.consultationForm)
          if (res.data.code===0) {
            this.$message.success("提交成功")
          }
          // 发送请求...
          this.dialogVisible = false;
        } else {
          alert('提交失败，联系电话不能为空');
          return false;
        }
      });
      }else{
        alert('请先勾选协议')
      }
    }
  }
};
</script>

<style lang="css" scoped>
  .l{
    position: relative;
  }
  ::v-deep .el-dialog__wrapper{
    z-index: 2005;
  }
  ::v-deep .el-dialog{
    height: 550px;
  }
 ::v-deep .el-input__inner{
  width: 420px !important;
 }
 ::v-deep .el-dialog__header{
  padding: 0;
 }
 ::v-deep .el-dialog__body{
  padding: 0;
  height: 100%;
 }
 .el-button--primary{
  background: #F58634 !important;
  border-color: #f58634 !important;
 }
 ::v-deep .safety2{
  color: #1ad2a0;
 }
 .r .text .dl1{
 }
 ::v-deep .r .text .dl1 dt{
  line-height: 52px;
  font-size: 14px;
 }
 ::v-deep .r .text .dl1 dd{
  line-height: 36px;
  color: #8a8a8a;
 }
</style>