<template>
  <!-- 对话框组件 -->
  <el-dialog title="" :lockScroll="false" :visible.sync="dialogVisible" width="40%" append-to-body style="text-align: center;">
    <div class="member_detail" style="text-align: center;">
      <!-- 图片 -->
      <img :src="image" alt="图片描述" style="width: 250px; ">
      <span>{{teamTitle}}</span>
      <div>{{company_position}}</div>
      <div>{{description}}</div>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
      };
    },
    props: {
      image: {
        type: String,
        default: ''
      },
      teamTitle: {
        type: String,
        default: ''
      },
      company_position: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      }
    },
    methods: {

    }
  };
</script>

<style lang="css" scoped>
  ::v-deep .el-dialog {
    height: auto;
  }

  .l {
    position: relative;
  }

  ::v-deep .el-input__inner {
    width: 420px !important;
  }

  .el-button--primary {
    background: #F58634 !important;
    border-color: #f58634 !important;
  }

  ::v-deep .safety2 {
    color: #1ad2a0;
  }

  .r .text .dl1 {}

  ::v-deep .r .text .dl1 dt {
    line-height: 52px;
    font-size: 14px;
  }

  ::v-deep .el-dialog__header {
    padding: 0;
  }

  ::v-deep .r .text .dl1 dd {
    line-height: 36px;
    color: #8a8a8a;
  }
  .member_detail{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .member_detail span {
    font-size: 24px;
    color: #101010;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-top: 30px;
    border-bottom: solid 2px #ff8b02;
    display: inline-block;
  }
  .member_detail div:nth-child(3){
    font-size: 18px;
    color: #575757;
    display: block;
  }
  .member_detail div:nth-child(4){
    margin-top: 20px;
  }
</style>