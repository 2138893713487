import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta:{title:'创业小鸟-让天下没有难创的业'}
  },

  {
    path: '/about',
    name: 'about',
    meta:{title:'关于我们-创业小鸟'},
    component: () => import('@/views/about'),
  },
  {
    path: '/certificate',
    name: 'certificate',
    meta:{title:'资质证书-创业小鸟'},
    component: () => import('@/views/about/certificate'),
  },
  {
    path: '/partner',
    name: 'partner',
    meta:{title:'合作客户-创业小鸟'},
    component: () => import('@/views/about/partner'),
  },
  {
    path: '/culture',
    name: 'culture',
    meta:{title:'企业文化-创业小鸟'},
    component: () => import('@/views/about/culture'),
  },
  {
    path: '/address',
    name: 'address',
    // meta:{title:'集群地址-创业小鸟'},
    component: () => import('@/views/address')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/address/info')
  },
  {
    path: '/infodetail',
    name: 'infodetail',
    component: () => import('@/views/address/infodetail')
  },
  {
    path: '/team',
    name: 'team',
    meta:{title:'顾问团队-创业小鸟'},
    component: () => import('@/views/about/team')
  },
  {
    path: '/teamdetail',
    name: 'teamdetail',
    meta:{title:'顾问团队-创业小鸟'},
    component: () => import('@/views/about/team/detail')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service')
  },
 
  {
    path: '/contact',
    name: 'contact',
    meta:{title:'联系方式-创业小鸟'},
    component: () => import('@/views/about/contact')
  },
  {
    path: '/join',
    name: 'join',
    meta:{title:'加入我们-创业小鸟'},
    component: () => import('@/views/about/join')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/about/news')
  },
  {
    path: '/media',
    name: 'media',
    meta:{title:'媒体资料-创业小鸟'},
    component: () => import('@/views/about/media')
  },
  {
    path: '/pay',
    name: 'pay',
    meta:{title:'支付方式-创业小鸟'},
    component: () => import('@/views/about/pay')
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('@/views/service/more')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta:{title:'创业小鸟隐私政策-创业小鸟'},
    component: () => import('@/views/license/privacy')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta:{title:'创业小鸟网络服务使用协议-创业小鸟'},
    component: () => import('@/views/license/agreement')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/service/detail')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: () => import('@/views/about/news/detail')
  },
  {
    path: '/policy',
    name: 'policy',
    meta:{title:'政策法规-创业小鸟'},
    component: () => import('@/views/policy')
  },
  {
    path: '/video',
    name: 'video',
    meta:{title:'企业视频-创业小鸟'},
    component: () => import('@/views/video')
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    meta:{title:'知识库-创业小鸟'},
    component: () => import('@/views/knowledge')
  },
  {
    path: '/party',
    name: 'party',
    meta:{title:'非公党建-创业小鸟'},
    component: () => import('@/views/party')
  },
  {
    path: '/partydetail/:id',
    name: 'partydetail',
    component: () => import('@/views/party/detail')
  },
  {
    path: '/policydetail/:id',
    name: 'policydetail',
    component: () => import('@/views/policy/detail')
  },
  {
    path: '/operate',
    name: 'operate',
    meta:{title:'经营范围-创业小鸟'},
    component: () => import('@/views/operate')
  },
  {
    path:'/sbfl',
    name:'sbfl',
    meta:{title:'商标分类-创业小鸟'},
    component:()=>import('../views/tradecategory')
   },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    //x代表横坐标，y代表纵坐标
    return {x:0,y:0};
  }
})

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title=to.meta.title
  }
  next()
})

export default router
